import React from 'react';
import styled from 'styled-components';
import Button from './Common/Button';
import logo from '../Images/logo.png';
import priceLogo from '../Images/logo-dog.png'
import Interface from '../metamask';
import { useWeb3 } from '@3rdweb/hooks';
import * as _useWeb3 from '@3rdweb/react';

interface Props {
    theme:string;
    manualConnect: () => void;
    address: string;
}

const Container = styled.div`
    min-width: 100%;
    min-height: 70px;
    background-color: transparent;
    display: flex;
    justify-content: flex-end;
`
const ConnectButton = styled(Button)`
    margin-top: 10px;
    margin-right: 10px;
    font-size: 15px;

    @media (max-width: 768px) {
        width: 150px;
    }

    @media (max-width: 425px) {
        font-size: 10px;
        flex: 1;
        margin-left: 15px;
        width: auto;
    }
`
const Logo = styled.img`
    height: 60px;
    margin-top: 5px;
    margin-left: 10px;
    @media (max-width: 500px) {
        display: none;
    }
`
const PriceContainer = styled.div`
    display: flex;
    height: 45px;
    margin-top: 12.5px;
    background-color: ${props => props.theme.priceBackground};
    border-radius: ${props => props.theme.borderRounding};
    margin-right: auto;
    margin-left: 10px;
    -webkit-box-shadow: ${props => props.theme.button.boxShadow};
    box-shadow: ${props => props.theme.button.boxShadow};

`
const PriceLogo = styled.img`
    margin-left: 2.5px;
    margin-top: 2.5px;
    height: 40px;
    @media (max-width: 425px) {
        display: none;
    }
`
const PriceText = styled.h3`
    margin-right: 15px; 
    margin-top: 15px;
    font-size: 15px;
    color: ${props => props.theme.textPrimary};
    font-family: 'MoreSugar-Regular', sans-serif;
    @media (max-width: 768px) {
        font-size: 12.5px
    }
    @media (max-width: 425px) {
        margin-left: 15px;
    }
`

const Nav:React.FC<Props> = props => {


    return (
        <Container>
            <Logo src={logo}/>
            <PriceContainer>    
                <PriceLogo src={priceLogo} />
                <PriceText>$0.0000000049119373342904954</PriceText>
            </PriceContainer>
            <ConnectButton onClick={props.manualConnect} primary theme={props.theme} height='50px' width='200px' text={props.address != null ? props.address.substring(0,5) + "..." + props.address.substring(38,44) : "Connect"}/>
        </Container>
    )
}

export default Nav;