import Nav from "../Components/Nav";
import React from "react";
import styled from "styled-components";
import Header from "../Components/Header";
import GameContainer from "../Components/GameContainer";
import Interface from "../metamask";
import { useWeb3 } from "@3rdweb/hooks";
import Footer from "../Components/Footer";
import Button from "./Common/Button";

interface Props {
  theme: string;
}

const Container = styled.div<Pick<Props, "theme">>`
  min-width: 100vw;
  display: flex;
  flex-wrap: wrap;
  background: ${(props) => props.theme.body};
  font-family: "Roboto Condensed", sans-serif;
  z-index: 1;
  position: relative;
  justify-content: center;
`;

const MigrationPanel = styled.div`
  width: 400px;
  display: flex;
  flex-wrap: wrap;
  border: 2.5px solid ${(props) => props.theme.body};
  margin-top: 45px;
  border-radius: 7.5px;
  margin-bottom: 20px;

  @media screen and (max-width: 420px) {
    margin-left: 1.25%;
    width: 95%;
  }
`;
const PanelRow = styled.div`
  display: flex;
  width: 100%;
`;
const PanelItem = styled.h3`
  font-size: 17.5px;
  color: white;
  margin: 0px;
  margin-left: 10px;
  margin-top: 10px;
`;
// TODO: Update text colors if needed
const PanelValue = styled.h3`
  font-size: 17.5px;
  color: white;
  margin: 0px;
  margin-right: 10px;
  margin-left: auto;
  margin-top: 10px;
  width: 45%;
  text-align: right;
`;
// TODO: Update text colors if needed
const Disclaimer = styled.p`
  font-size: 17.5px;
  width: 100%;
  color: white;
  margin-left: 10px;
  margin-top: 10px;
`;
// TODO: Deposit/Approve button styled
const DepositButton = styled(Button)`
  width: 90%;
  margin-left: 5%;
  height: 40px;
  color: white;
  margin-bottom: 10px;
  margin-top: 10px;

  &:hover {
    background-color: #63ab3e;
    color: white;
  }
`;
const Heading = styled.h3`
  width: 100%;
  margin: 0px;
  margin-bottom: 20px;
  font-size: 30px;
  text-align: center;
  padding: 0px;
`;

const Main: React.FC<Props> = (props) => {
  const { address, connectWallet, provider } = useWeb3();
  const [connected, setConnected] = React.useState(false);
  const [connectMenuOpen, setConnectMenuOpen] = React.useState(false);
  const [approved, setApproved] = React.useState(false);
  const [data, setData] = React.useState({
    v1Balance: 0,
    v2Balance: 0,
    allowance: 0,
  });

  React.useEffect(() => {
    if (address == null) {
      connectWallet("injected");
      Interface.connectWallet(provider?.provider);
    }
  }, []);

  React.useEffect(() => {
    if (address != null) {
      Interface.connectWallet(provider?.provider);
      setConnected(true);
    }
  }, [address, provider]);

  React.useEffect(() => {
    let interval = setInterval(async () => {
      if (provider) {
        let _data = await Interface.getData();
        setData(_data);
      }

    }, 5000);
  }, [address, provider]);

  const handleMigrate = async () => {
    if (data.allowance <= data.v1Balance) {
      await Interface.approve();
    } else {
      await Interface.migrate();
    }
  };

  const manualConnect = () => {
    if (address == null) {
      connectWallet("injected");
      Interface.connectWallet(provider?.provider);
      setConnected(true);
    }
  };

  return (
    <Container theme={props.theme}>
      <Nav address={address} manualConnect={manualConnect} theme={props.theme} />
      <Header theme={props.theme} />
      <MigrationPanel>
        <Heading>Migration</Heading>
        <PanelRow>
          <PanelItem>Tokens to Migrate:</PanelItem>
          <PanelValue>{`${parseFloat(data.v1Balance.toFixed(2)).toLocaleString("en-US")} BONK`}</PanelValue>
        </PanelRow>
        <PanelRow>
          <PanelItem>Tokens Migrated:</PanelItem>
          <PanelValue>{`${parseFloat(data.v2Balance.toFixed(2)).toLocaleString("en-US")} BONK`}</PanelValue>
        </PanelRow>
        {!approved ? <Disclaimer>*Approve tokens for swap on migration contract:</Disclaimer> : ""}
        <DepositButton text={data.allowance > data.v1Balance ? "Migrate" : "Approve"} primary onClick={async () => await handleMigrate()}></DepositButton>
      </MigrationPanel>
      <GameContainer connected={connected} theme={props.theme} />
      <Footer theme={props.theme} />
    </Container>
  );
};

export default Main;
