import React from "react";
import styled from "styled-components";
import Button from "./Common/Button";
import Unity, { UnityContext } from "react-unity-webgl";


interface Props {
    theme: string;
    connected: boolean;
}

const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    min-width: 80%;
    max-width: 80%;
    border-top: 2px dashed ${props => props.theme.textAlt};
    margin-top: 50px;
    min-height: 600px;
`
const ErrorContainer = styled.div`
    min-width: 80%;
    max-width: 80%;
    margin-top: 225px;
`
const ErrorHeading = styled.h3`
    text-align: center;
    color: ${props => props.theme.textPrimary};
    font-size: 27.5px;
    font-family: 'MoreSugar-Regular', sans-serif;
    width: 100%;
`
const ErrorDescription = styled.p`
    font-size: 20px;
    text-align: center;
    color: ${props => props.theme.textAlt};
    font-family: 'MoreSugar-Thin', sans-serif;
    margin-top: -10px;
`
const Game = styled(Unity)`
    max-width: 100%;
    min-width: 100%;
    max-height: 80%;
    min-height: 50%;
    margin-top: 50px;
`

const GameContainer: React.FC<Props> = props => {
    const unityContext = new UnityContext({
        loaderUrl: "assets/game/WhackABonk.loader.js",
        dataUrl: "assets/game/WhackABonk.data",
        frameworkUrl: "assets/game/WhackABonk.framework.js",
        codeUrl: "assets/game/WhackABonk.wasm",
    });

    const [error, setError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [gameWon, setGameWon] = React.useState(false);

    React.useEffect(() => {
        if (!props.connected) {
            console.log('hitting')
            setError(true);
            setErrorMessage('Connect your wallet to load the Whack a Bonk! game.')
        } else {
            setError(false);
        }
    }, [props.connected])

    React.useEffect(function () {
        unityContext.on("GameWon", function () {
          setGameWon(true);
        });
      }, []);

    return (
        <Container>
            {
                !error ? 
                    <Game unityContext={unityContext} /> 
                : 
                    <ErrorContainer>
                        <ErrorHeading>Ooops!</ErrorHeading>
                        <ErrorDescription>{errorMessage}</ErrorDescription>
                    </ErrorContainer>
                }
        </Container>
    )
}

export default GameContainer;