export const defaultTheme = { 
    body: '#152028',
    bodyLighter: '#233643',
    textPrimary: '#F9A03F',
    textAlt: '#CAD9E3',
    borderRounding: '7.5px',
    priceBackground: '#464C5D',

    button: {
        primaryBG: 'linear-gradient(90deg, rgba(249,160,63,1) 0%, rgba(166,107,43,1) 100%);',
        borderRounding: '10px',
        textColor: 'white',
        boxShadow: '0px 0px 15px 10px rgba(0,0,0,0.15)',
    }
}

export const lightTheme = { 
    body: 'radial-gradient(circle, rgba(249,160,63,0.1) 0%, rgba(185,118,45,0.1) 100%);',
    bodyLighter: '#233643',
    textPrimary: '#F9A03F',
    textAlt: '#585F74',
    borderRounding: '7.5px',
    priceBackground: '#585F74',

    button: {
        primaryBG: 'linear-gradient(90deg, rgba(249,160,63,1) 0%, rgba(166,107,43,1) 100%);',
        borderRounding: '10px',
        textColor: 'white',
        boxShadow: '0px 0px 15px 10px rgba(0,0,0,0.15)',
    }
}